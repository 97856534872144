import {Component} from "react";
import {Game} from "../../@types/games/Game";
import {GamePlatform} from "../../@types/games/GamePlatform";
import {Platform} from "../../@types/games/Platform";

export type GameMetadataProps = {
	game: Game;
};

export default class GameMetadata extends Component<GameMetadataProps, any> {
	private _game: Game;

	constructor(props: GameMetadataProps) {
		super(props);
		this._game = props.game;
	}

	releaseDate(): JSX.Element {
		if(this._game.toBeReleased) {
			return <></>;
		}

		let release: Date = new Date(this._game.releaseDate!);
		return <span className="badge bg-info">
			Vydáno {release.getDate()}. {release.getMonth()}. {release.getFullYear()}
		</span>
	}

	linkToGame(platform: GamePlatform, index: number): JSX.Element {
		let platformService: string;
		switch(platform.platform) {
			case Platform.Windows:
				platformService = this._game.price === 0 ? "Zahraj si na steamu" : "Kup si na steamu / "+this._game.price+"€";
				break;
			case Platform.Android:
				platformService = "Stáhni na google play"
				break;
			case Platform.Browser:
				platformService = "Zahraj si v prohlížeči";
				break
		}

		return (
			<a href={platform.link} target="_blank" rel="noreferrer" className="badge bg-primary d-inline-block mx-1" key={index}>
				{platformService}
			</a>
		);
	}

	gamePlatforms(): JSX.Element[] {
		if(this._game.gamePlatforms === undefined) {
			return [];
		}

		let platforms: JSX.Element[] = [];
		this._game.gamePlatforms.forEach((platform, index) => {
			platforms.push(this.linkToGame(platform, index));
		});

		return platforms;
	}

	render(): JSX.Element {
		return (
			<div className={"my-4"}>
				{this.releaseDate()} {this.gamePlatforms()}
			</div>
		);
	}
}
