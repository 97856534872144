import {Component} from "react";
import {Alert, Spinner} from "reactstrap";
import ApiRequest, {QueryParams} from "../../api/ApiRequest";
import {Envelope} from "../../api/Envelope";
import Paginator from "./Paginator";

export type ApiComponentState<TDto> = {
	envelope?: Envelope<TDto>;
	loaded: boolean;
	hasError: boolean;
	limit: number;
	offset: number;
};

export type ApiComponentProps<TDto> = {
	preloaded?: Envelope<TDto>;
};

/**
 * The base component for loading data from the api.
 */
export default abstract class ApiComponent<TDto, TProps extends ApiComponentProps<TDto>, TState extends ApiComponentState<TDto>> extends Component<TProps, TState> {
	protected constructor(props: TProps) {
		super(props);

		// @ts-ignore
		this.state = {
			data: undefined,
			loaded: false,
			hasError: false,
			limit: Paginator.defaultLimit,
			offset: 0
		} as ApiComponentState<TDto>;

		this.handlePageChange = this.handlePageChange.bind(this);
	}

	/**
	 * Defines the endpoint which should be called to receive target data.
	 * @protected
	 */
	protected abstract get endpoint(): string;

	/**
	 * Defines additional query parameters. Overridable method.
	 * In default behavior, this is not setting any parameters.
	 * @protected
	 */
	protected get query(): QueryParams {
		return {
			limit: this.state.limit.toString(),
			offset: this.state.offset.toString()
		};
	}

	/**
	 * Access the loaded data.
	 * @protected
	 */
	protected get data(): Envelope<TDto> {
		if(this.state.envelope === undefined) {
			throw new Error("Accessing not loaded data.");
		}

		return this.state.envelope as Envelope<TDto>;
	}

	componentDidMount() {
		this.loadData();
	}

	/**
	 * Loads the data from the api.
	 * @private
	 */
	protected loadData(): void {
		if(this.props.preloaded !== undefined) {
			this.setState({envelope: this.props.preloaded, loaded: true});
		} else {
			ApiRequest.get(this.endpoint, this.query)
				.then(response => {
					if(response.ok) {
						response.json().then((value: Envelope<TDto>) => {
							this.setState({envelope: value, loaded: true});
						});
					} else {
						this.setState({envelope: undefined, loaded: false, hasError: true});
					}
				});
		}
	}

	private handlePageChange(limit: number, offset: number): void {
		console.log("Page changed with limit " + limit + " and offset " + offset);

		this.setState({
			limit: limit,
			offset: offset
		}, () => {
			this.loadData()
		});
	}

	/**
	 * Custom rendering of the component.
	 * @protected
	 */
	protected abstract renderComponent(): JSX.Element;

	render(): JSX.Element {
		if(this.state.hasError) {
			return (
				<Alert color="danger">
					Během nahrávání dat se vyskytla chyba. Zkuste opakovat akci později.
				</Alert>
			);
		}

		if(this.state.loaded) {
			return (
				<>
					{this.renderComponent()}
					<Paginator itemCount={this.data.total} onChange={this.handlePageChange} />
				</>
			);
		}

		return (
			<Alert color="primary" className="d-flex align-middle">
				<Spinner color="primary" type="grow"/>
				<p className="d-inline-block mx-3 mb-0 d-flex align-items-center">Nahrávám data...</p>
			</Alert>
		)
	}
}
