import ApiUri from "./ApiUri";

export enum Method {
	Get,
	Post,
	Patch,
	Put,
	Delete
}

enum ContentType {
	Json,
	File
}

export type QueryParams = string[][] | Record<string, string> | string | URLSearchParams | null;

export default class ApiRequest {
	private readonly _uri: ApiUri;
	private readonly _method: Method;
	private readonly _params: QueryParams;
	private _contentType: ContentType = ContentType.Json;
	private _body?: any = undefined;

	constructor(method: Method, uri: ApiUri, params: QueryParams) {
		this._params = params;
		this._uri = uri;
		this._method = method;
	}

	static create(method: Method, endpoint: string, params: QueryParams = null): ApiRequest {
		return new ApiRequest(method, new ApiUri(endpoint), params);
	}

	static get(endpoint: string, params: QueryParams = null): Promise<Response> {
		return ApiRequest.create(Method.Get, endpoint, params).send();
	}

	static post(endpoint: string, body: any): Promise<Response> {
		let request: ApiRequest = ApiRequest.create(Method.Post, endpoint);
		request.body = body;

		return request.send();
	}

	static postFile(endpoint: string, file: File): Promise<Response> {
		let request: ApiRequest = ApiRequest.create(Method.Post, endpoint);
		request.contentType = ContentType.File;

		let formData: FormData = new FormData();
		formData.append("file", file);
		request.body = formData;

		return request.send();
	}

	static patch(endpoint: string, body: any): Promise<Response> {
		let request: ApiRequest = ApiRequest.create(Method.Patch, endpoint);
		request.body = body;

		return request.send();
	}

	static put(endpoint: string, body: any): Promise<Response> {
		let request: ApiRequest = ApiRequest.create(Method.Put, endpoint);
		request.body = body;

		return request.send();
	}

	static delete(endpoint: string): Promise<Response> {
		return ApiRequest.create(Method.Delete, endpoint).send();
	}

	set contentType(value: ContentType) {
		this._contentType = value;
	}

	set body(value: any) {
		this._body = value;
	}

	private get method(): string {
		switch (this._method) {
			case Method.Post:
				return "POST";
			case Method.Put:
				return "PUT";
			case Method.Patch:
				return "PATCH";
			case Method.Delete:
				return "DELETE";
			default:
				return "GET";
		}
	}

	send(): Promise<Response> {
		let init: RequestInit = {
			method: this.method,
			headers: {}
		};
		init = this.setBody(init);

		let url: URL = new URL(this._uri.endpoint);
		if (this._params !== null) {
			url.search = new URLSearchParams(this._params).toString();
		}

		return fetch(url, init);
	}

	private setBody(init: RequestInit): RequestInit {
		if (this._body !== undefined) {
			switch(this._contentType) {
				case ContentType.Json:
					init.body = JSON.stringify(this._body);
					// @ts-ignore
					init.headers["Content-Type"] = "application/json";
					break;
				case ContentType.File:
					init.body = this._body;
					break;
			}
		}

		return init;
	}
}
