import {Component} from "react";
import {PageNavbar} from "./PageNavbar";
import {Container} from "reactstrap";
import Footer from "./Footer";

export default class Layout extends Component<any> {
	render(): JSX.Element {
		return (
			<>
				<PageNavbar/>
				<Container fluid={true} className="px-0">
					{this.props.children}
				</Container>
				<Footer/>
			</>
		);
	}
}
