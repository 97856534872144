import {Component, ReactNode} from "react";
import {Container} from "reactstrap";

export type PageHeaderProps = {
	children?: ReactNode;
	title: string;
}

export default class PageHeader extends Component<PageHeaderProps> {
	private renderDescription(): JSX.Element {
		if(this.props.children === undefined) {
			return <></>;
		}

		return <h5 className="text-white-50 mt-3">{this.props.children}</h5>;
	}

	render(): JSX.Element {
		return (
			<div className="bg-primary bg-opacity-50 py-5">
				<Container>
					<h1 className="display-1">{this.props.title}</h1>
					{this.renderDescription()}
				</Container>
			</div>
		);
	}
}
