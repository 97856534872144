import React, {Component} from 'react';
import Layout from "./components/common/Layout";
import "./bootstrap/css/bootstrap.min.css";
import {Outlet} from "react-router-dom";

export default class App extends Component<any> {
	render(): JSX.Element {
		return (
			<Layout>
				<Outlet/>
			</Layout>
		);
	}
}
