import {Component} from "react";
import PageHeader from "../components/common/PageHeader";
import GameList from "../components/games/GameList";

export default class OurGames extends Component<any, any> {

	render(): JSX.Element {
		return (
			<>
				<PageHeader title="Naše hry">
					Naši pozornost aktuálně zaměřujeme na menší, ale originální díla, která si lze užít na mobilech i počítačích.
				</PageHeader>
				<GameList />
			</>
		);
	}
}
