import ApiUri from "./ApiUri";
import {ImageSizeType} from "../@types/content/files/ImageSizeType";

export default class ImageUri extends ApiUri {
	constructor(imageId: string, size?: ImageSizeType) {
		if(size !== undefined) {
			super(`file/image/${imageId}/${size.toString()}`);
		}
		else {
			super(`file/image/${imageId}`);
		}
	}
}
