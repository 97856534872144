import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import OurGames from "./pages/OurGames";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Privacy from "./pages/Privacy";

const baseUrl: any = document.getElementsByTagName('base')[0].getAttribute('href');
const root: ReactDOM.Root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
	<BrowserRouter basename={baseUrl}>
		<Routes>
			<Route path="/" element={<App/>}>
				<Route index element={<OurGames/>}/>
				<Route path="games" element={<OurGames/>}/>
				<Route path="blog" element={<Blog/>}>

				</Route>
				<Route path="contact" element={<Contact/>}/>
				<Route path="privacy" element={<Privacy/>}/>
			</Route>
		</Routes>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
