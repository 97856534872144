import {Component} from "react";
import {Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink} from "reactstrap";
import logo from "./../../images/sagittaras-logo-transparent.png";
import {Link} from "react-router-dom";

export class PageNavbar extends Component {
	private renderNavigation(): JSX.Element {
		return (
			<>
				<NavbarToggler/>
				<Collapse navbar>
					<Nav className="mx-auto" navbar>
						<NavItem>
							<NavLink tag={Link} to="/games">Naše hry</NavLink>
						</NavItem>
						<NavItem>
							<NavLink tag={Link} to="/blog">Blog</NavLink>
						</NavItem>
						<NavItem>
							<NavLink tag={Link} to="/contact">Kontakt</NavLink>
						</NavItem>
					</Nav>
				</Collapse>
			</>
		);
	}

	render(): JSX.Element {
		return (
			<Navbar color="black" dark={true} className="py-3 border-bottom border-1 border-dark" expand="md"
			        container={true}>
				<NavbarBrand href="/">
					<img src={logo} alt="Sagittaras Games" height={50}/>
				</NavbarBrand>
			</Navbar>
		);
	}
}
