import {Component} from "react";

export default class Privacy extends Component<any, any> {
	render(): JSX.Element {
		return (
			<>
				<div className="mb-5">
					<h1 className="display-1 text-center">Privacy Policy</h1>
					<h4 className="text-muted text-center">Last updated September 24, 2022</h4>
				</div>
				<div className="container">
					<p>Sagittaras Games is czech entertainment company specialized in the publishing of mobile games and applications.</p>
					<p>The purpose of this policy is to provide you with all the important information and explanations about <strong>how and why some of your data may be collected and processed by Sagittaras Games when you use one of our applications or browse on our website</strong>. This privacy policy governs and details the main principles that Sagittaras Games applies to the data we collect and process to:</p>
					<p>
						(i) develop, publish and monetize mobile games and applications (“Sagittaras Games Apps and Games”), and <br/>
						(ii) run our website (“sagittaras.cz”).
					</p>
					<p>
						This policy also aims to <strong>remind you about your rights and to provide you with all the elements you need to exercise them</strong>.
					</p>
					<p>
						Should you have any questions related to this policy or our practices around privacy and data protection in general, please <strong>don’t hesitate to contact us at <a href="mailto:support@sagittaras.cz">support@sagittaras.cz</a></strong> as indicated in the contact section of this policy.
					</p>

					<h2>Sagittaras Games apps and games</h2>
					<h3>Why does Sagittaras Games collect data from its apps and games?</h3>
					<p>When you install and use Sagittaras Games mobile applications, we can collect and process some of your data for different legitimate purposes.</p>
					<p>You will find below explanations regarding the reasons why Sagittaras Games may collect data and the legal bases Sagittaras Games relies on in each case. </p>
					<p>Sagittaras Games collects data:</p>
					<ul>
						<li>
							<strong>To provide you with the services you asked for</strong> <br/> (e.g., knowing that you completed the first level to allow access to the second one)
							<ul>
								<li>Legal basis – such data processing is strictly necessary for the service(s) asked</li>
							</ul>
						</li>
						<li>
							<strong>To run analytics and understand how users interact with our product and services in order to continuously improve it either directly or through third party partners</strong> <br/>
							(e.g. identifying that a feature is annoying for users or that a level is too difficult for most gamers)
							<ul>
								<li>Legal basis for third party analytics - Express consent. In the countries where collecting users’ consent may be required by applicable laws and regulations, Sagittaras Games only shares personal data collected through Sagittaras Games apps with third party partners that provide Sagitaras Games with analytics services once you express your consent through the pop-up notice included in our apps.</li>
								<li>Legal basis for first party analytics – Legitimate interest. Sagittaras may collects user data for the purpose of its own internal analytics tools. Data is used for the sole purpose of Sagittaras Games analytics tool and is not shared with any third parties. This data processing is based on the legitimate interest of Sagittaras Games.</li>
							</ul>
						</li>
						<li>
							<strong>To serve advertising tailored to the preference and interests of our users and allow Sagittaras Games to continue to provide free services and products</strong>
							<ul>
								<li>Legal basis – Express consent. In the countries where collecting users’ consent may be required by applicable laws and regulations, Sagittaras Games only processes or shares personal data collected through Sagittaras Games apps for personalized advertising purposes once you express your consent through the pop-up notice included in one of our apps.</li>
							</ul>
						</li>
					</ul>

					<h3>What data does Sagittaras Games collect from its apps and games?</h3>
					<p>For all the purposes listed above the data that Sagittaras Games collect is limited to:</p>
					<ul>
						<li>The apps you are using</li>
						<li>Your IP address</li>
						<li>Your Mobile Advertising ID (Google AAID - which is technical identifier developed by mobile operating system for advertising purposes which remain under your control and can be reset or erased at any time in through your device settings)</li>
						<li>Technical information about the device you use and your connection (user agent, type of connection, timestamp)</li>
						<li>Data pertaining to your activities on our applications and notably the way in which you interact with our applications (for instance, how and when you use our applications) and with the advertising served in our applications (for instance, number of ads served, potential clicks)</li>
						<li>Failure diagnostics and data</li>
						<li>Approximate location</li>
					</ul>

					<h3>With who may your data be shared & why?</h3>
					<p>Sagittaras Games does not share your personal data with third parties without your prior consent in the countries where collecting users’ consent may be required by applicable laws and regulations</p>
					<p>When you consent to the collection of data for advertising and analytics purpose, we may share the data listed above with the following categories of recipients:</p>

					<ul>
						<li><strong>Ad Partners:</strong> that allow us to monetize the ad inventory of our apps and provide users with free products and services.
							Those partners usually collect data via their own tools (Software Development Kits or “SDK”). You will find a list of our partners implementing advertising SDKs through our applications and the privacy policies of their services that describe their practices and allow you to exercise your rights directly toward them hereafter:
							<ul>
								<li>Google AdMob - <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">https://policies.google.com/privacy</a></li>
							</ul>
						</li>
						<li><strong>Analytics Comapnies:</strong>  that provide us with tools and technologies that allow us to better understand how users interact with our services and will help us improve it.
							Those partners also usually collect data via their own SDKs. You will find a list of our partners implementing analytical SDKs through our applications hereafter:
							<ul>
								<li>Game Analtics - <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">https://policies.google.com/privacy</a></li>
								<li>Google Analtics - <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">https://policies.google.com/privacy</a></li>
							</ul>
						</li>
						<li><strong>Authorities:</strong> only to the extent we are obliged to by applicable laws and regulations.</li>
					</ul>

					<h2>Sagittaras Games website</h2>
					<h3>What data does Sagittaras Games collect from its website?</h3>
					<p>For the purposes listed above the data that Sagittaras Games and its partners collect is limited to:</p>
					<ul>
						<li>Browsing events and interactions with the website’s contents and services</li>
						<li>Anonymized IP address and technical information related to your browser and/or device (user agent)</li>
						<li>Information you provide us with voluntarily when you contact Sagittaras Games</li>
					</ul>

					<h3>Analytics and third party cookies</h3>
					<p>Sagittaras Games uses Google Analytics to collect information related to the web traffic on our website. This service allows us to measure the performance and proper functioning of our website and to provide you with a better experience. Sagittaras Games made sure to configure this tool in such a way that the information collected for this purpose is anonymized in a way that does not allow to identify any end users of our website.</p>
					<p>For more information on the data protection and privacy aspects of these analytics services, you can refer to Google specific policy accessible here: <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">https://policies.google.com/privacy</a><br/>
						and opt-out from Google Analytics data collection here: <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noreferrer">https://tools.google.com/dlpage/gaoptout</a></p>

					<h3>Social networks and other communication tools</h3>
					<p>Our website also offers communication tools allowing users to share content on social networks. When you interact with such social media widgets or “share buttons”, these social network companies may collect information about you and/or your device and connection. Your interactions with these services are governed by the respective privacy policies of the companies providing these services. For more information on the data protection and privacy practices of these companies, you can refer to their specific policies listed below:</p>
					<ul>
						<li>Facebook: <a href="https://www.facebook.com/about/privacy/" target="_blank" rel="noreferrer">https://www.facebook.com/about/privacy/</a></li>
						<li>LinkedIn: <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank" rel="noreferrer">https://www.linkedin.com/legal/privacy-policy</a></li>
					</ul>

					<h2>Security and other important information</h2>
					<h3>Children data</h3>
					<p>Sagittaras Games <strong>never</strong> knowingly or willingly <strong>collect any personal data concerning children under 13</strong> years of age.</p>

					<h2>What rights do you have?</h2>
					<h3>Access right</h3>
					<p>Upon request, Sagittaras Games will provide you with information about whether we hold any of your personal information. You may access or request deletion of your personal information directly in Sagittaras Games mobile apps or by contacting <a href="mailto:support@sagittaras.cz">support@sagittaras.cz</a> as indicated in the “Contact” section below. We will respond to your request within a reasonable timeframe.</p>

					<h3>Rectification and erasure</h3>
					<p>You may request Sagittaras Games the rectification of inaccurate personal data concerning you, as well as the completion of incomplete personal data. You may also request Sagittaras Games to erase without undue delay your personal data when it is no longer necessary for Sagittaras Games to retain such data.</p>
					<p>In order for your data to be erased or rendered inaccessible you can either: </p>
					<ul>
						<li>reset your mobile advertising identifier (GAAID on Android) or activate “Limit Ad Tracking” in your device’ settings in order to make previously collected data non linkable to you or your device anymore and limit further data collection if you choose to enable the “Limit Ad Tracking” option</li>
						<li>contact <a href="mailto:support@sagittaras.cz">support@sagittaras.cz</a>, as indicated in the “Contact” section below in order to have the data previously collected by Sagittaras Games on you and/or your device permanently erased or anonymized</li>
					</ul>

					<h2>Portability</h2>
					<p>Upon request, Sagittaras Games will provide you with the personal data that you provided to us and, if possible, will communicate this information directly to another data controller of your choice in a portable format when the processing is based on consent or contract.</p>

					<h2>Right to lodge a complaint</h2>
					<p>You may lodge a complaint with a Data Protection Authority. Sagittaras Games being a czech company.</p>

					<h2>Contact</h2>
					<p>Data Protection Officer – Jan Zechovský – Bezděkov 117, 438 01, Žatec, Czech republic</p>
					<p><a href="mailto:support@sagittaras.cz">support@sagittaras.cz</a></p>
				</div>
			</>
		);
	}
}
