export default class ApiUri {
	private readonly _baseUri: string;
	private readonly _endpoint: string;

	constructor(endpoint: string) {
		let env = process.env;

		this._baseUri = env.REACT_APP_API_SCHEME + "://" + env.REACT_APP_API_HOST + ":" + env.REACT_APP_API_PORT + "/" + env.REACT_APP_API_BASE_URL;
		this._endpoint = this._baseUri + "/" + endpoint;
	}

	get endpoint(): string {
		return this._endpoint;
	}
}
