import {Component} from "react";

export type PaginatorProps = {
	/**
	 * The total number of items.
	 */
	itemCount: number;

	/**
	 * Callback when the page changes.
	 * @param limit The current limit of elements to be displayed.
	 * @param offset The current offset.
	 */
	onChange: (limit: number, offset: number) => void;
}

export type PaginatorState = {
	/**
	 * Current number of items to be displayed.
	 */
	itemsPerPage: number;

	/**
	 * Current page.
	 */
	page: number;
}

export default class Paginator extends Component<PaginatorProps, PaginatorState> {
	constructor(props: PaginatorProps) {
		super(props);

		this.state = {
			itemsPerPage: Paginator.defaultLimit,
			page: 1
		};
	}

	public static get defaultLimit(): number {
		return 10;
	}

	private get limit(): number {
		return this.state.itemsPerPage;
	}

	private get offset(): number {
		return this.state.itemsPerPage * (this.state.page - 1);
	}

	private get pageCount(): number {
		return Math.ceil(this.props.itemCount / this.state.itemsPerPage);
	}

	private handlePageChange(page: number): void {
		this.setState({page: page}, () => this.props.onChange(this.limit, this.offset));
	}

	private renderButtons(): JSX.Element[] {
		let buttons: JSX.Element[] = [];

		for (let i = 1; i <= this.pageCount; i++) {
			buttons.push(
				<li key={i} className={"page-item" + (this.state.page === i && " active")}>
					<button className="page-link" onClick={() => this.handlePageChange(i)}>{i}</button>
				</li>
			);
		}

		return buttons;
	}

	render(): JSX.Element {
		if (this.pageCount <= 1) {
			return <></>;
		}

		return (
			<>
				<nav>
					<ul className="pagination justify-content-center">
						{this.renderButtons()}
					</ul>
				</nav>
				<p className="text-center">
					Celkový počet záznamů - {this.props.itemCount}
				</p>
			</>
		);
	}
}
