import {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram, faLinkedin, faPatreon, faGithub} from "@fortawesome/free-brands-svg-icons";

export default class SocialIcons extends Component {
	render(): JSX.Element {
		return (
			<div className="d-flex justify-content-center py-3 gap-3">
				<a href="https://facebook.com/sagittaras" target="_blank" rel="noreferrer">
					<FontAwesomeIcon icon={faFacebookF} /> Facebook
				</a>
				<a href="https://instagram.com/sagittaras" target="_blank"  rel="noreferrer">
					<FontAwesomeIcon icon={faInstagram} /> Instagram
				</a>
				<a href="https://linkedin.com/company/sagittaras" target="_blank" rel="noreferrer">
					<FontAwesomeIcon icon={faLinkedin} /> LinkedIn
				</a>
				<a href="https://patreon.com/sagittaras" target="_blank" rel="noreferrer">
					<FontAwesomeIcon icon={faPatreon} /> Patreon
				</a>
				<a href="https://github.com/sagittaras" target="_blank" rel="noreferrer">
					<FontAwesomeIcon icon={faGithub} /> GitHub
				</a>
			</div>
		);
	}
}
