import ApiComponent from "../api/ApiComponent";
import {Game} from "../../@types/games/Game";
import {GameDetail} from "./GameDetail";

export default class GameList extends ApiComponent<Game[], any, any> {
	protected get endpoint(): string {
		return "game";
	}

	protected renderComponent(): JSX.Element {
		return (
			<>
				{this.data.data.map((game: Game, index: number) => {
					return <GameDetail game={game} isEven={index%2 === 0} key={game.id} />
				})}
			</>
		);
	}

}
