import {Component} from "react";
import SocialIcons from "./SocialIcons";

export default class Footer extends Component {
	render(): JSX.Element {
		return (
			<footer className="border-1 border-top border-dark">
				<SocialIcons/>
				<p className="text-center">
					Copyright &copy; Sagittaras Games. All right reserved.
				</p>
			</footer>
		)
	}
}
