import {Component} from "react";
import {GameReview} from "../../@types/games/GameReview";

export type GameReviewsProps = {
	gameReviews: GameReview[];
}

export class GameReviews extends Component<GameReviewsProps> {
	renderSingleReview(review: GameReview, index: number) {
		return (
			<figure key={index}>
				<blockquote className="blockquote">
					<p>„{review.quote}“</p>
				</blockquote>
				<figcaption className="blockquote-footer">
					{review.score}&nbsp;
					<cite>
						<a href={review.link} target="_blank" rel="noreferrer">{review.medium}</a>
					</cite>
				</figcaption>
			</figure>
		);
	}

	render(): JSX.Element {
		if(this.props.gameReviews.length === 0) {
			return <></>;
		}

		return (
			<div className={"mt-4"}>
				<h2>Recenze</h2>
				{this.props.gameReviews.map((review, index) => {
					return this.renderSingleReview(review, index);
				})}
			</div>
		);
	}
}
