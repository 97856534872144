import {Component} from "react";
import {Game} from "../../@types/games/Game";
import {Container} from "reactstrap";
import {GameReviews} from "./GameReviews";
import parse from "html-react-parser";
import GameMetadata from "./GameMetadata";
import ImageUri from "../../api/ImageUri";

export type GameDetailProps = {
	game: Game;
	isEven: boolean;
}

export class GameDetail extends Component<GameDetailProps, any> {
	private readonly _game: Game;

	constructor(props: GameDetailProps) {
		super(props);
		this._game = props.game;
	}

	setImageOrder(): string {
		return this.props.isEven ? "order-1" : "order-0";
	}

	renderGameReviews(): JSX.Element {
		if (this._game.gameReviews === undefined) {
			return <></>;
		}

		return <GameReviews gameReviews={this._game.gameReviews}/>;
	}

	renderBasicDetails(): JSX.Element {
		return (
			<>
				<h1>{this._game.name}</h1>
				<h5><GameMetadata game={this._game}/></h5>
				{parse(this._game.description)}
			</>
		)
	}

	renderDetailBody(): JSX.Element {
		if(this._game.gameLogoId === undefined) {
			return (
				<div className={"mt-5"}>
					{this.renderBasicDetails()}
				</div>
			);
		}

		return (
			<>
				<div className="row mt-5">
					<div className={"col-md-4 " + this.setImageOrder()}>
						<img src={new ImageUri(this._game.gameLogoId).endpoint} alt={this._game.name} className="img-fluid"/>
					</div>
					<div className="col-md-8">
						{this.renderBasicDetails()}
						{this.gameToBeReleased()}
					</div>
				</div>
				{this.renderGameReviews()}
			</>
		);
	}

	gameToBeReleased(): JSX.Element {
		if(!this._game.toBeReleased) {
			return <></>;
		}

		return (
			<div className={"alert alert-dark mt-5"}>
				<h4>Zajímá tě více?</h4>
				<p className={"mb-0"}>Exkluzivní informace můžeš sledovat na <a href="https://patreon.com/sagittaras">našem patreonu</a>!</p>
			</div>
		)
	}

	render(): JSX.Element {
		return (
			<Container fluid={true} className={"bg-opacity-25 " + (this.props.isEven ? "bg-dark" : "bg-black")}>
				<Container className={"py-5"}>
					{this.renderDetailBody()}
				</Container>
			</Container>
		)
	}
}
